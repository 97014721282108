import * as React from 'react';
import { Box, Typography, Link, Alert,TextField, Grid, Container} from '@mui/material';
import { useContext } from 'react';
import UserLicenseInfoContext from '../../../utils/contexts/UserLicenseInfoContext';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';
import CopyToClipboardButton from '../../CopyToClipboardButton';

interface apply_stat {
    apply_stat: string
  }

function LotusNotesContent ({ apply_stat }: apply_stat) {

    const userLicenseInfo = useContext(UserLicenseInfoContext);
    const pwd = userLicenseInfo?.ACCOUNT_LOTUS_NOTES_RESULT;
    let showPwd = undefined;
    let copyToClipboardButton = undefined;
  
    if (JSON.stringify(pwd) === '{}') {
      showPwd = '';
    } else {
      showPwd = pwd;
      copyToClipboardButton = (
        <Grid item>
          <CopyToClipboardButton index={0} type='lotusnotes' />
        </Grid>
      );
    }

    const { t } = useTranslation();
    return ( 
        <Box>
            <Typography sx={{ pt: 5, display: 'flex', justifyContent: 'left',fontWeight: 400}} variant='subtitle1' >
                {t("lotusnotes.header1")}
            </Typography>
            <Typography sx={{pt: 2, display: 'flex', justifyContent: 'left',fontWeight: 400}} variant='subtitle1' >
                {t("lotusnotes.header2")}
            </Typography>
            <Alert severity='warning' sx={{ mt: 2}}>
                <Trans i18nKey="lotusnotes.warning"
                    components={{
                        link1: <Link href="https://faq.icto.um.edu.mo/how-to-change-your-lotus-notes-password-and-lotus-notes-internet-password/" target="_blank" rel="noreferrer" />
                    }}>
                </Trans>
            </Alert>
            <Container disableGutters maxWidth="sm" sx={{ justifyContent: 'center', alignContent: 'center', mt: 5}}>
            <Grid container>
                <Grid item>
                    <Typography variant='h6' sx={{fontWeight: 550, pt: 0.5, mb: {xs: 1, sm: 1}, mr: {sm: 1, md: 1}}}>
                         {t("lotusnotes.initialpwd")}
                    </Typography>
                </Grid>
                <Grid item sx={{ml: {xs: 0, sm:0 , md: 2}, mb: 3}}>
                    <TextField 
                    id="notespwd"
                    defaultValue={showPwd}  
                    sx={{ mr: {xs: 2, sm: 2, md: 4, lg: 4}, width: {xs:205, sm: 205} }} 
                    InputProps={{ readOnly: true, }}
                    size="small"
                    />
                </Grid>
                {copyToClipboardButton}

            </Grid>
            </Container>
            
        </Box>
    );
};
export default withTranslation()(LotusNotesContent)