import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LicenseDatas } from './LicenseData';
import { createTheme,ThemeProvider, styled } from '@mui/material/styles';
import { Container, Checkbox, FormControlLabel, FormGroup,  IconButton, CircularProgress, Dialog, DialogActions } from '@mui/material';
import MuiButton from '@mui/material/Button'
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorMsgDialog from '../HandleMsgDialog';
import { useTranslation  } from 'react-i18next';

interface id {
  id: number,
  apply_stat: String,
  contentStat: String,
  setapply: (val: string) => void,
  setContentStat: (val: string) => void,
  parentHandleClose: () => void
};

const style = {
  display: 'flex',
  justifyContent: 'center',
  border: '0',
  borderRadius: 4,
  boxShadow: 0,
  mt: -3,
};
const theme = createTheme();

const Button = styled(MuiButton)((props) => ({
  backgroundColor: "#005f96",
  color: "white",
  marginTop: 18,
  paddingLeft: 50,
  paddingRight: 50,
  '&:hover' : {
    backgroundColor: "#123456",
  },
  [theme.breakpoints.down('md')]: {
    marginTop: 5,
  },
  "&.Mui-disabled": {
    background: "#A9A9A9",
    color: "white"
  }
}));


export default function LicenseApplyPage( {id, apply_stat, setapply, contentStat, setContentStat, parentHandleClose }: id )  {
  const [agree, setAgreement] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  // const [mainMode, setMainMode] = useState<boolean>(false);
  const checkAgreement = () => setAgreement(!agree);

  const [errorMsgDialogOpen, setErrorMsgDialogOpen] = useState(false);
  const [errorMsgDialogTitle, setErrorMsgDialogTitle] = useState("Sorry!");
  const [errorMsgDialogContent, setErrorMsgDialogContent] = useState("The service is temporarily unavailable and please try again later. We apologize for any inconveniences caused.");
  const [needReload, setNeedReload] = useState<boolean>(true);
  const { t } = useTranslation();

  // Set ESET for maintenance mode and disable license apply, ESET id = 5
  // useEffect(() => {
  //   if (id === 5) {
  //     setMainMode(true)
  //   }
  // }, [id]);

  const handleMsgDialogOpen = (title: string, content: string) => {
    setErrorMsgDialogTitle(title);
    setErrorMsgDialogContent(content);
    setErrorMsgDialogOpen(true);
  };

  const handleMsgDialogClose = () => {
    setErrorMsgDialogOpen(false);
    setLoading(false);
    setAgreement(false);
    if (!needReload) {
      parentHandleClose();
    }
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<id>();  


  // console.log("apply_stat's type"+ typeof(apply_stat))
  // console.log("contentStat's type"+ typeof(contentStat))

  const onApplySubmit = async () => {
    if (!agree) {
      handleMsgDialogOpen(`${t("applylicense.warning")}`, `${t("applylicense.checkWarn")}`)
      throw new Error('Error: User not checked agree!');
    }

    if (!loading) {
      setLoading(true);
    }

    try {
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_CONTEXT_PATH}/api/license/apply/${LicenseDatas[id].api}`,
      });
      // console.log(`${process.env.REACT_APP_CONTEXT_PATH}/api/license/apply/${LicenseDatas[id].api}`)
      // console.log(response)
      if (response.status === 200) {
        // var result = String(response.data);
        if (response.data.code === 600) {
          handleMsgDialogOpen(`${t("applylicense.warning")} (${response.data.pcode})`, response.data.data)
          setNeedReload(false);
        } else {
          setContentStat("yes");
          setNeedReload(true);
        }
        setLoading(false);
        // axios.get(`${process.env.REACT_APP_CONTEXT_PATH}/api/admin/clear_cache`)
      } else {
        handleMsgDialogOpen("applylicense.errHeader", `${t("applylicense.errmsg", {license_name: LicenseDatas[id].title})}`)  
        setNeedReload(false);
        throw new Error(`Error: Not able to apply ${LicenseDatas[id].title}`);
      }
    } catch (error) {
      handleMsgDialogOpen(`${t("common.serviceErr")}`, String(error))
      setNeedReload(false);
      console.log(error);
    }
  };

 return (
    <Box sx={style}>
      <form onSubmit={handleSubmit(onApplySubmit)}>
        <Container sx={{ display: 'grid'}}>
          <Box sx={{ mx: {xs: 0, sm: 0, md: 2, lg: 2, xl: 2} }}>
            { contentStat === "no" ?
                <Box>
                {LicenseDatas[id].apply_agreement}
                <FormGroup sx={{ mt: 1, mb: {xs:2, xl: 0}, display: id === 9? 'none': 'inherit' }}>
                  <FormControlLabel control={<Checkbox checked={agree} onChange={checkAgreement} />} label={t("button.agreechkbox")} />
                </FormGroup>
                <Button size="large" disabled={!agree || loading } onClick={onApplySubmit} sx={{mb: 3, display: id === 9? 'none': 'inherit'}}>
                  {t("button.applynow")}
                </Button> 
                {loading && (
                    <CircularProgress
                      size={35}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                )}
                </Box>:
                   (apply_stat !== contentStat) ? (id === 5) ? 
                      <Box sx={{ mt: 3, mb: 3}}>
                        <Typography sx={{ display: 'flex', justifyContent: 'left',fontWeight: 600, alignItems: 'center'}} variant='subtitle1' >
                          <CheckCircleOutlineIcon
                              color='success'
                              sx={{ fontSize: 40, alignContent: 'center', pr: 2 }}
                          />
                          {t("applylicense.success")}
                        </Typography>
                      {LicenseDatas[id].temp_content}
                      </Box> :
                      <Box sx={{ mt: 3, mb: 3}}>
                        <Typography sx={{ display: 'flex', justifyContent: 'left',fontWeight: 600, alignItems: 'center'}} variant='subtitle1' >
                          <CheckCircleOutlineIcon
                            color='success'
                            sx={{ fontSize: 40, alignContent: 'center', pr: 2 }}
                          />
                          {t("applylicense.success")}
                        </Typography>
                        {LicenseDatas[id].content}
                    </Box> : 
                  <Box sx={{ mb: 3}}>{LicenseDatas[id].content}</Box>
              }
          </Box>
        <ErrorMsgDialog open={errorMsgDialogOpen} onClose={handleMsgDialogClose} errorTitle={errorMsgDialogTitle} errorContent={errorMsgDialogContent} needReload={needReload}/>
        </Container>
      </form>
      </Box>
  );
}