import * as React from 'react';
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Box } from "@mui/system";
import { createTheme,ThemeProvider, styled, useTheme, withStyles  } from '@mui/material/styles';
import LicenseApplyPage from './LicenseApplyPage';
import { DialogProps } from '@mui/material/Dialog';
import { useState, useEffect, useRef } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import axios, {AxiosError} from 'axios';
import UserLicenseInfoContext,{ UserLicenseInfo } from '../../utils/contexts/UserLicenseInfoContext'
import { useContext } from 'react';
import isDarkMode from '../../utils/contexts/IsDarkMode';


interface LicenseData {
    id: number,
    name: string,
    title: string,
    desc: string,
    bgcolor: string,
    icon: React.ReactNode,
    apply_stat: string,
    content: React.ReactNode,
    temp_content: React.ReactNode,
    apply_agreement: React.ReactNode,
    setapply: (val: string) => void
    setUserLicenseInfo: (val: UserLicenseInfo) => void
    
};

const theme = createTheme()

export default function LicenseCard ({ id, name, title, bgcolor, icon, apply_stat, apply_agreement, setapply, setUserLicenseInfo}: LicenseData) {
  const isDark = useContext(isDarkMode)
  let tcolor = isDark? '#eae6e6':'#005F96';
  let licenseID: number = id;
  const ref = useRef<HTMLElement>(null); 

  const [agree, setAgreement] = useState<boolean>(false);
  // const [loading, setLoading] = React.useState(false);
  // const checkAgreement = () => setAgreement(!agree);

  const [openApply, setOpen] = React.useState(false);
  const [contentStat, setContentStat] = useState<String>(apply_stat)

  const curtheme = useTheme();
  const fullScreen = useMediaQuery(curtheme.breakpoints.down('md'));
  
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    if (contentStat !== apply_stat) { 
      setapply("yes");
      window.location.reload() 
    }
  };

  if (apply_stat === "no") {
    tcolor = "#717575"
    bgcolor="#f8f9fa"
  } else {
    if (isDark) {
      bgcolor="#343131"
    }
  }

  useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = 0;
      }
  }, [contentStat]);

  const contenticontheme = createTheme({
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '5.3rem',
            color: isDark ? "white" : "#005f96",
            [theme.breakpoints.down('md')]: {
              fontSize: '2.2rem',
            },
          },
        },
      },
    },
  });
 
  const icontheme = createTheme({
      components: {
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              fontSize: '5.3rem',
              color: (apply_stat === 'yes') ? (isDark? "#eae6e6": "#005f96") : "#717575",
              marginTop: 35,
              [theme.breakpoints.down('md')]: {
                fontSize: '2.2rem',
                display: 'flex',
                marginTop: 0,
                marginRight: 10,
                marginLeft: 16,
                paddingTop: 12,
                paddingBottom: 12
              },
            },
          },
        },
      },
    });

  return (
      <Grid item xs={12} md={4} lg={4}>
        <Paper elevation={1} sx={{ bgcolor: bgcolor, borderRadius: {xs: 4, sm: 4, md: 0}, paddingBottom: {md: 5, lg: 5} }}>
          <Container onClick={handleOpen} disableGutters sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle', flexDirection: {sm: 'row' ,md:'column', lg: 'column'}}}>
            <ThemeProvider theme={icontheme}>
              {icon}
            </ThemeProvider>
            <Typography sx={{ 
                            mt: {sm: 0, md: 3},
                            ml: 1,
                            color: `${tcolor}`,
                            flexGrow: 1,
                            fontWeight: {xs: 550, sm: 550, md: 500},
                            fontSize: {md: 18}
                          }}
            >{title}</Typography>
          </Container>        
      </Paper>
      <Dialog
        open={openApply}
        onClose={handleClose}
        aria-labelledby="apply-license-page"
        scroll="paper"
        // fullWidth={ {xs: true, sm: true, md: false, lg: false} }
        fullScreen={fullScreen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // width: "100%",
              maxWidth: { md: 'md', lg: 'lg', xl: 'xl'},  // Set your width here
              borderRadius: {xs:0, sm: 0, md: 4, lg: 4, xl: 4},
            },
          },
        }}
        // sx={{  display: 'flex', justifyContent: 'center', mt: 10  }}
      >
        <DialogTitle sx={{ pt: 5 }}>
          <center>
            <ThemeProvider theme={contenticontheme}>
              {icon}
            </ThemeProvider>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: isDark ? 'text.primary': '#005f96' }} >
              {title}
            </Typography>
          </center>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 40,
              top: 15,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={ref} >
          <LicenseApplyPage id={licenseID} apply_stat={apply_stat} setapply={setapply} contentStat={contentStat} setContentStat={setContentStat} parentHandleClose={handleClose} />     
        </DialogContent>
      </Dialog>
    </Grid>
 
  );
};