import * as React from 'react';
import {Divider, Box, Typography, Link} from '@mui/material'
import { Trans, withTranslation, useTranslation } from 'react-i18next';

interface apply_stat {
    apply_stat: string
  }

function OpenDataContent ({ apply_stat }: apply_stat) {
    const { t } = useTranslation();
    return ( 
        <Box>
            { apply_stat === "yes" ?
                <Box>
                    <Typography sx={{ pt: 5, fontWeight: 400}} variant='subtitle1'>
                        <Trans i18nKey="opendata.header_applied">
                            <Link href='https://data.um.edu.mo' target='_blank' rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Typography variant='h5' sx={{ mt: 4}}>
                        {t("opendata.getstart")}
                    </Typography>
                    <Typography variant='subtitle1'>
                        <Trans i18nKey="opendata.body">
                            <Link href='https://data.um.edu.mo' target='_blank' rel="noreferrer" />
                        </Trans>
                    </Typography>
                    <Divider sx={{mt:3, mb:2}}></Divider>
                    <Typography variant='subtitle1'><Trans i18nKey="opendata.moreinfo">
                        <Link href="https://data.um.edu.mo" target="_blank" rel="noreferrer" />
                    </Trans>
                    </Typography>
                </Box> : null
            }
            {   apply_stat === "no" ?
                <Box>
                    <Typography sx={{ pt: 5,}}>{t("opendata.header_notapply")}</Typography>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    <Typography variant='subtitle1'>
                    <Trans i18nKey="opendata.agreement">
                        <Link href="https://faq.icto.um.edu.mo/acceptable-use-policy-icto-computing-facilities-campus-network-and-internet/" target="_blank" rel="noreferrer" />
                        <Link href="https://data.um.edu.mo/terms-and-conditions-of-use/" target="_blank" rel="noreferrer" />
                    </Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                     {t("common.personal_data")}
                    </Typography>
                    <Typography variant='subtitle1' component='ol'>
                        <Trans i18nKey="opendata.data_transfer" components={{
                            o: <li />,
                            link1: <Link href="https://aka.ms/apimrocks" target="_blank" rel="noreferrer" />,
                            link2: <Link href="https://www.microsoft.com/microsoft-365/onedrive/" target="_blank" rel="noreferrer" />
                        }}></Trans>
                    </Typography>
                    <Typography variant='subtitle1'  sx={{ fontWeight: 600, mt: 2}}>
                     {t("common.agree_note")}
                    </Typography>
                    <Typography variant="subtitle1" component='ul'>
                        <Trans i18nKey="opendata.notice" components={[<li></li>, <li></li>]}></Trans>
                    {/* <li>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</li>
                    <li>The Terms shall be governed in accordance with the laws of the Macau Special Administrative Region of the People’s Republic of China (“Macau”).</li> */}
                    </Typography>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(OpenDataContent);